import _consoleControlStrings from "console-control-strings";
import _renderTemplate from "./render-template.js";
import _aproba from "aproba";
var exports = {};
var consoleControl = _consoleControlStrings;
var renderTemplate = _renderTemplate;
var validate = _aproba;

var Plumbing = exports = function (theme, template, width) {
  if (!width) width = 80;
  validate("OAN", [theme, template, width]);
  this.showing = false;
  this.theme = theme;
  this.width = width;
  this.template = template;
};

Plumbing.prototype = {};

Plumbing.prototype.setTheme = function (theme) {
  validate("O", [theme]);
  this.theme = theme;
};

Plumbing.prototype.setTemplate = function (template) {
  validate("A", [template]);
  this.template = template;
};

Plumbing.prototype.setWidth = function (width) {
  validate("N", [width]);
  this.width = width;
};

Plumbing.prototype.hide = function () {
  return consoleControl.gotoSOL() + consoleControl.eraseLine();
};

Plumbing.prototype.hideCursor = consoleControl.hideCursor;
Plumbing.prototype.showCursor = consoleControl.showCursor;

Plumbing.prototype.show = function (status) {
  var values = Object.create(this.theme);

  for (var key in status) {
    values[key] = status[key];
  }

  return renderTemplate(this.width, this.template, values).trim() + consoleControl.color("reset") + consoleControl.eraseLine() + consoleControl.gotoSOL();
};

export default exports;