import _process from "./process";
import _process2 from "process";
var exports = {};
var process = _process2;
var process = _process;

try {
  exports = process.nextTick;
} catch (ex) {
  exports = process.nextTick;
}

export default exports;