import _process from "process";
var exports = {};
var process = _process;
exports = isWin32() || isColorTerm();

function isWin32() {
  return process.platform === "win32";
}

function isColorTerm() {
  var termHasColor = /^screen|^xterm|^vt100|color|ansi|cygwin|linux/i;
  return !!process.env.COLORTERM || termHasColor.test(process.env.TERM);
}

export default exports;